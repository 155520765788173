import qs from 'qs';
import type { LoaderFunction } from 'react-router';

import { createUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PrefetchContextData, PrefetchUserContextData } from 'src/shared/prefetch';
import type { AppRoute } from 'src/shared/routes';
import type { Session } from 'src/shared/session';

import type { AppCache } from 'src/app/shared/hooks/cache';
import { isRestrictedAccessCheckNecessary } from 'src/app/shared/routes';
import { prefetchComponentData, prefetchComponentUserData } from 'src/app/shared/utils/prefetch';
import type { AppOptions } from 'src/app/types';

import { checkRestrictedAccess } from './checkRestrictedAccess';

export type RouteLoaderData = {
	prefetchedUserData?: PrefetchUserContextData;
	prefetchedData?: PrefetchContextData;
	prefetchedSession?: Session;
};

export type RouteLoaderFunctionFactory = (routeConfig: AppRoute) => LoaderFunction;

export type ClientOptions = Pick<AppOptions, 'utilities' | 'l10n' | 'queryClient'> & {
	context: { cache?: AppCache; session?: Session };
};

export function createClientRouteLoaderFactory({ context, ...options }: ClientOptions): RouteLoaderFunctionFactory {
	const utilityContext = createUtilityContext(options.utilities);

	return (routeConfig) =>
		async ({ params, request }): Promise<RouteLoaderData> => {
			// cache and session are set at first render, so it won't be available on the first data prefetch
			// first data prefetch should be handled by hydration data anyway (done in src/app/createClientApp)
			if (!context.cache || !context.session) {
				return {};
			}

			const restrictedAccessCheckNecessary = isRestrictedAccessCheckNecessary(routeConfig);

			const url = new URL(request.url, window.location.origin);

			if (restrictedAccessCheckNecessary) {
				if (
					!(await checkRestrictedAccess({
						url,
						route: routeConfig,
						params,
						session: context.session,
						utilityContext,
					}))
				) {
					// never resolve as we want to prevent rendering the route
					// and let the page change/reload occur
					// eslint-disable-next-line promise/avoid-new
					return new Promise(() => {});
				}
			}

			const prefetchContext = {
				utilityContext,
				l10n: options.l10n,
				cache: context.cache,
				path: url.pathname,
				params,
				query: qs.parse(url.search, { ignoreQueryPrefix: true }),
				session: context.session,
				queryClient: options.queryClient,
			};
			const { cache, ...prefetchUserContext } = prefetchContext;
			const prefetchedSession = context.session;

			const prefetchedUserDataPromise = prefetchComponentUserData(routeConfig.component, prefetchUserContext);
			const [prefetchedUserData, prefetchedData] = await Promise.all([
				prefetchedUserDataPromise,
				prefetchComponentData(routeConfig.component, prefetchContext, prefetchedUserDataPromise),
			]);

			return { prefetchedUserData, prefetchedData, prefetchedSession };
		};
}
