import { useState } from 'react';

import type { PrefetchUserContext } from 'src/shared/prefetch';

import { getFcm, useFcm } from './fcm';

export function useMaintenanceBanner(prefetchedMessageParam?: string): string {
	const [prefetchedMessage] = useState(prefetchedMessageParam);

	// FIXME: could we find a way to not request the FCM if prefetchedMessage is defined?
	const maintenanceBannerMessage = useFcm();

	if (prefetchedMessage) return prefetchedMessage;
	if (maintenanceBannerMessage.status === 'loaded') {
		return maintenanceBannerMessage.values.enabled || '';
	}
	return '';
}

export async function prefetchMaintenanceBanner({ utilityContext }: PrefetchUserContext): Promise<string> {
	const maintenanceBannerMessage = await getFcm(utilityContext);

	return maintenanceBannerMessage.enabled || '';
}
