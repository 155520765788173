import { useMemo } from 'react';

import type { PrefetchUserContext } from 'src/shared/prefetch';

import { prefetchTermsOfServiceBanner, useTermsOfServiceBanner } from '../termsOfService';

import type { Banner } from './types';

type PrefetchedBannersData = Awaited<ReturnType<typeof prefetchTermsOfServiceBanner>>;

export function useOrderedBanners(prefetchedData?: PrefetchedBannersData): readonly Banner[] {
	const {
		isTermsOfServiceBannerDisplayed,
		dismissTermsOfServiceBanner,
		termsOfServiceBannerCopy,
		handleTermsOfServiceBannerView,
		trackTermsOfServiceLinkClick,
	} = useTermsOfServiceBanner(prefetchedData);

	return useMemo(() => {
		const banners: Banner[] = [];
		if (isTermsOfServiceBannerDisplayed)
			banners.push({
				type: 'tos',
				dismiss: dismissTermsOfServiceBanner,
				handleView: handleTermsOfServiceBannerView,
				trackLinkClick: trackTermsOfServiceLinkClick,
				copy: termsOfServiceBannerCopy,
			});
		return banners;
	}, [
		isTermsOfServiceBannerDisplayed,
		dismissTermsOfServiceBanner,
		termsOfServiceBannerCopy,
		handleTermsOfServiceBannerView,
		trackTermsOfServiceLinkClick,
	]);
}

export async function prefetchBanners(context: PrefetchUserContext): Promise<PrefetchedBannersData> {
	return prefetchTermsOfServiceBanner(context);
}
