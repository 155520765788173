import type { Params } from '@remix-run/router';
import qs from 'qs';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	AppRoute,
	AppRouteRestrictedAccessCheckContext,
	RestrictedAccessExpectedBehavior,
} from 'src/shared/routes';
import type { Session } from 'src/shared/session';

import { checkRestrictedAccess, isRestrictedAccessCheckNecessary } from 'src/app/shared/routes';

import { getRestrictedAccessCheckSessionInfo } from './api';

export async function getExpectedBehavior({
	route,
	url,
	params,
	session,
	utilityContext,
}: {
	route: AppRoute;
	url: URL;
	params: Params;
	session: Session;
	utilityContext: UtilityContext;
}): Promise<RestrictedAccessExpectedBehavior | 'external' | undefined> {
	if (!isRestrictedAccessCheckNecessary(route)) return undefined;

	const context: AppRouteRestrictedAccessCheckContext = {
		path: url.pathname,
		params,
		query: qs.parse(url.search, { ignoreQueryPrefix: true }),
		session,
		utilities: utilityContext,
	};

	try {
		return (
			(await checkRestrictedAccess(route, { loginState: session.loginState, roles: session.user?.roles }, context)) ||
			(await (async () => {
				const { loginState, user } = await getRestrictedAccessCheckSessionInfo(utilityContext);

				// user id changed between client-side route changes => let's navigate externally to the page to be safe
				if (user?.id !== session.user?.id) {
					return 'external';
				}

				return checkRestrictedAccess(route, { loginState, roles: session.user?.roles }, context);
			})())
		);
	} catch (e) {
		// failed to get data => let's navigate externally to the page to be safe
		return 'external';
	}
}
