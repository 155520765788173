// unless there is a bug, this should only impact CSR-only mode (port 3333) in dev

import type { JSX, PropsWithChildren } from 'react';

import qs from 'qs';
import { useLocation, useParams } from 'react-router';

import { useQuery } from '@change-corgi/core/react/async';
import { Redirect } from '@change-corgi/core/react/router';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';
import { getWindowSafe } from '@change-corgi/core/window';
import { Loader } from '@change-corgi/design-system/components/progressiveDisclosure';

import { ForbiddenError } from 'src/shared/error';
import type { AppRoute } from 'src/shared/routes';
import type { Session } from 'src/shared/session';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { checkRestrictedAccess, getLoginRedirectUrl } from 'src/app/shared/routes';
import { isError, isLoaded, isLoading } from 'src/app/shared/utils/async';

// as the restricted access check logic is already implemented as a server middleware and in the client routing
export const CheckRestrictedAccess = (() => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-member-access
	if (process.env.NODE_ENV === 'production' || !(getWindowSafe() as any)?.CSR) {
		// eslint-disable-next-line react/function-component-definition, @typescript-eslint/no-shadow
		return function CheckRestrictedAccess({ children }: PropsWithChildren<EmptyIntersection>): JSX.Element {
			return <>{children}</>;
		};
	}

	// eslint-disable-next-line react/function-component-definition, @typescript-eslint/no-shadow
	function CheckRestrictedAccessInner({
		route,
		session,
		children,
	}: PropsWithChildren<{ session: Session; route: AppRoute }>): JSX.Element | null {
		const utilityContext = useUtilityContext();
		const location = useLocation();
		const params = useParams();
		const expectedBehaviorState = useQuery(
			async () => ({
				expectedBehavior: await checkRestrictedAccess(
					route,
					{
						loginState: session.loginState,
						roles: session.user?.roles,
					},
					{
						path: location.pathname,
						params,
						query: qs.parse(location.search, { ignoreQueryPrefix: true }),
						session,
						utilities: utilityContext,
					},
				),
			}),
			// only checking for the first render of the route
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		);

		if (isLoading(expectedBehaviorState)) return <>{children}</>;

		if (isError(expectedBehaviorState)) return <>Oups, an error has occurred while checking the permissions</>;

		switch (expectedBehaviorState.expectedBehavior) {
			case 'login_page':
				return <Redirect forceMode="external" to={getLoginRedirectUrl()} />;
			case 'forbidden':
				throw new ForbiddenError();
			default:
				return <>{children}</>;
		}
	}

	// eslint-disable-next-line react/function-component-definition, @typescript-eslint/no-shadow
	return function CheckRestrictedAccess({
		route,
		children,
	}: PropsWithChildren<{ route: AppRoute }>): JSX.Element | null {
		const sessionState = useSessionAsync();

		if (!isLoaded(sessionState)) return <Loader size="m" my={256} mx="auto" />;

		return (
			<CheckRestrictedAccessInner session={sessionState.value} route={route}>
				{children}
			</CheckRestrictedAccessInner>
		);
	};
})();
