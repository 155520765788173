import { useCallback, useEffect, useState } from 'react';

import type { PrefetchUserContext } from 'src/shared/prefetch';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import type { PrefetchedData } from './types';
import { prefetchBannerType, useBannerType } from './useBannerType';
import { getConfigFcm, useConfigFcm } from './useConfigFcm';
import { prefetchCookie, useCookie } from './useCookie';
import { prefetchCopy, useCopy } from './useCopy';
import { useTracking } from './useTracking';

type Result = {
	isTermsOfServiceBannerDisplayed: boolean;
	dismissTermsOfServiceBanner: () => void;
	handleTermsOfServiceBannerView: () => void;
	trackTermsOfServiceLinkClick: () => void;
	termsOfServiceBannerCopy: Readonly<{ body: string }>;
};

export function useTermsOfServiceBanner(prefetchedDataParam?: PrefetchedData): Result {
	const [prefetchedData] = useState(prefetchedDataParam);

	const session = useSessionAsync();

	// FIXME: could we find a way to not request the FCM if prefetchedData is defined?
	const fcmState = useConfigFcm();
	const config = isLoaded(fcmState) ? fcmState.values.tosConfig : undefined;

	const bannerType = useBannerType(config, prefetchedData);

	const { getCount, decreaseCount } = useCookie(bannerType, config, prefetchedData);

	const { trackDismiss, trackBannerView, trackLinkClick } = useTracking(bannerType);

	const [displayBanner, setDisplayBanner] = useState(!!prefetchedData?.tosBannerType);

	const isTermsOfServiceBannerDisplayed = !!bannerType && displayBanner;

	const dismissTermsOfServiceBanner = useCallback(() => {
		trackDismiss();
		decreaseCount(0);
		setDisplayBanner(false);
	}, [decreaseCount, trackDismiss]);

	const [viewHandled, setViewHandled] = useState(false);

	const handleTermsOfServiceBannerView = useCallback(() => {
		if (!isLoaded(session) || viewHandled) return;

		// do following only when session is available (for server-cached pages),
		// to make sure we have access to cookie prefs
		setViewHandled(true);
		trackBannerView();
		decreaseCount();
	}, [decreaseCount, trackBannerView, session, viewHandled]);

	useEffect(() => {
		// do not look at the cookie value if we have a prefetched value
		if (prefetchedData) return;
		setDisplayBanner(getCount() > 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // we only want to do this once at the start

	const termsOfServiceBannerCopy = useCopy(bannerType, config, prefetchedData);

	return {
		isTermsOfServiceBannerDisplayed: termsOfServiceBannerCopy && isTermsOfServiceBannerDisplayed,
		dismissTermsOfServiceBanner,
		termsOfServiceBannerCopy,
		handleTermsOfServiceBannerView,
		trackTermsOfServiceLinkClick: trackLinkClick,
	};
}

const emptyPrefetchedData: PrefetchedData = {
	tosBannerType: undefined,
	tosBannerEffectiveDate: '',
	tosBannerRange: 0,
	tosBannerMaxDisplayCount: 0,
	tosBannerCopy: '',
};

export async function prefetchTermsOfServiceBanner(context: PrefetchUserContext): Promise<PrefetchedData> {
	const fcm = await getConfigFcm(context.utilityContext);
	const config = fcm.tosConfig;
	if (!config) {
		return emptyPrefetchedData;
	}

	const bannerType = prefetchBannerType(config);
	if (!bannerType) {
		return emptyPrefetchedData;
	}

	const remainingDisplayCount = prefetchCookie(context, bannerType, config);
	const tosBannerCopy = await prefetchCopy(context, bannerType, config);
	if (!remainingDisplayCount || !tosBannerCopy) {
		return emptyPrefetchedData;
	}

	return {
		tosBannerType: bannerType,
		tosBannerEffectiveDate: config.effectiveDate,
		tosBannerRange: config[bannerType === 'post' ? 'postEffectiveRange' : 'preEffectiveRange'],
		tosBannerMaxDisplayCount: config.maxDisplayCount,
		tosBannerCopy,
	};
}
