import type { Session } from 'src/shared/session';

import { useLocalStorageValueAsync } from 'src/app/shared/hooks/storage';

export type CookiePrefsRequest = Record<
	keyof Omit<Session['cookiePrefs'], '__typename'>,
	{ consent_text: string; status: boolean }
>;

function parsePrefs(prefs: string | undefined | null) {
	if (prefs === undefined || prefs === null) return prefs;
	try {
		return JSON.parse(prefs) as CookiePrefsRequest;
	} catch (e) {
		return null;
	}
}

/**
 * useLocalStorageCookiePrefs returns the value of cookie prefs when found in localStorage.
 * If the hook is invoked on the first render (incl server-side), it returns undefined.
 * If the _change_cookie_prefs key cannot be found in localStorage, it returns null.
 */
export function useLocalStorageCookiePrefs(): CookiePrefsRequest | null | undefined {
	const [prefs] = useLocalStorageValueAsync('_change_cookie_prefs');

	return parsePrefs(prefs);
}
